<template>
    <div>
        <div class="contentBox">
            <div class="card">
                <div class="head">
                    <span class="xing">*</span>
                    <span class="title">上传身份证</span>
                    <!-- <span class="tips">上传后智能识别内容</span> -->
                </div>
                <div class="pic">
                    <div class="picBox" >
                        <van-uploader :after-read="afterReadImg1" :max-count="1" ref="location1">
                            <template>
                                <img class="defaltImg" v-show="!imageUrl1" src="../../../assets/credit/positive.png"
                                    alt="" />
                                <img class="showImg" v-show="imageUrl1" :src="imageUrl1" alt="" />
                            </template>
                        </van-uploader>
                        <div class="title" v-show="!imageUrl1">上传身份证正面</div>
                    </div>
                    <div class="picBox" >
                        <van-uploader :after-read="afterReadImg2" :max-count="1" ref="location2">
                            <template>
                                <img class="defaltImg" v-show="!imageUrl2" src="../../../assets/credit/side.png"
                                    alt="" />
                                <img class="showImg" v-show="imageUrl2" :src="imageUrl2" alt="" />
                            </template>
                        </van-uploader>
                        <div class="title" v-show="!imageUrl2">上传身份证正面</div>
                    </div>
                </div>
                <div class="picTips">以上传图片信息为准，请仔细核对您提交的信息</div>
                <div class="nameBox" >
                    <div class="left">
                        <span class="xing">*</span>
                        <span class="title">姓名</span>
                    </div>
                    <div class="right">
                        <van-field v-model="pageParams.name"  ref="location3" class="labelInput" placeholder="请填写姓名" />
                    </div>
                </div>
                <div class="nameBox line" ref="location4">
                    <div class="left">
                        <span class="xing">*</span>
                        <span class="title">性别</span>
                    </div>
                    <div class="right" @click="genderShowFun('person')" >
                        <span :style="pageParams.sex==null ? { color: '#9B9B9B' } : ''">{{pageParams.sex==null?'请选择':genderPersonText}}</span>
                        <img  src="../../../assets/credit/jiantou.png" alt="">
                    </div>
                </div>
                <div class="nameBox line" ref="location5">
                    <div class="left">
                        <span class="xing">*</span>
                        <span class="title">民族</span>
                    </div>
                    <div class="right" @click="nationShowFun('person')">
                        <span    :style="pageParams.nation=='' ? { color: '#9B9B9B' } : ''">{{pageParams.nation==''?'请选择':pageParams.nation}}</span>
                        <img src="../../../assets/credit/jiantou.png" alt="">
                    </div>
                </div>
                <div class="nameBox line" >
                    <div class="left">
                        <span class="xing">*</span>
                        <span class="title">年龄</span>
                    </div>
                    <div class="right">
                        <van-field ref="location6" v-model="pageParams.age" class="labelInput" placeholder="周岁" />
                    </div>
                </div>
                <div class="nameBox line" >
                    <div class="left">
                        <span class="xing">*</span>
                        <span class="title">身份证号</span>
                    </div>
                    <div class="right">
                        <van-field ref="location7" v-model="pageParams.idCard" class="labelInput" placeholder="请填写身份证号" />
                    </div>
                </div>
                <div class="nameBox line" >
                    <div class="left">
                        <span class="xing">*</span>
                        <span class="title">联系电话</span>
                    </div>
                    <div class="right">
                        <van-field ref="location8" v-model="pageParams.phone" class="labelInput" placeholder="请填写联系电话" />
                    </div>
                </div>
                <div class="nameBox line" >
                    <div class="left">
                        <span class="xing">*</span>
                        <span class="title">户籍编号</span>
                    </div>
                    <div class="right">
                        <van-field ref="location9" v-model="pageParams.householdRegistrationId" class="labelInput" placeholder="请填写" />
                    </div>
                </div>
                <div class="nameBox line">
                    <div class="left">
                        <span class="xing">*</span>
                        <span class="title">所属团场连队</span>
                    </div>
                    <div class="right">
                        <van-field  ref="location10" v-model="pageParams.teamOrFarmId" rows="1" class="labelInput" autosize
                            type="textarea" placeholder="请填写" show-word-limit />
                    </div>
                </div>
            </div>
            <!-- 配偶 -->
            <div class="card spouse">
                <div class="head">
                    <span class="title">配偶身份证</span>
                    <!-- <span class="tips">上传后智能识别内容</span> -->
                </div>
                <div class="pic">
                    <div class="picBox">
                        <van-uploader :after-read="afterReadImg3" :max-count="1">
                            <template>
                                <img class="defaltImg" v-show="!imageUrl3" src="../../../assets/credit/positive.png"
                                    alt="" />
                                <img class="showImg" v-show="imageUrl3" :src="imageUrl3" alt="" />
                            </template>
                        </van-uploader>
                        <div class="title" v-show="!imageUrl3">上传身份证正面</div>
                    </div>
                    <div class="picBox">
                        <van-uploader :after-read="afterReadImg4" :max-count="1">
                            <template>
                                <img class="defaltImg" v-show="!imageUrl4" src="../../../assets/credit/side.png"
                                    alt="" />
                                <img class="showImg" v-show="imageUrl4" :src="imageUrl4" alt="" />
                            </template>
                        </van-uploader>
                        <div class="title" v-show="!imageUrl4">上传身份证正面</div>
                    </div>
                </div>
                <div class="picTips">以上传图片信息为准，请仔细核对您提交的信息</div>
                <div class="nameBox">
                    <div class="left">
                        <span class="title">配偶姓名</span>
                    </div>
                    <div class="right">
                        <van-field v-model="pageParams.spouseName" class="labelInput"  placeholder="请填写配偶姓名" />
                    </div>
                </div>
                <div class="nameBox line">
                    <div class="left">
                        <span class="title">性别</span>
                    </div>
                    <div class="right" @click="genderShowFun('spouse')">
                        <span  :style="pageParams.spouseSex==null ? { color: '#9B9B9B' } : ''">{{pageParams.spouseSex==null?'请选择':genderSpouseText}}</span>
                        <img src="../../../assets/credit/jiantou.png" alt="">
                    </div>
                </div>
                <div class="nameBox line">
                    <div class="left">
                        <span class="title">民族</span>
                    </div>
                    <div class="right" @click="nationShowFun('spouse')">
                        <span  :style="pageParams.spouseNation=='' ? { color: '#9B9B9B' } : ''">{{pageParams.spouseNation==''?'请选择':pageParams.spouseNation}}</span>
                        <img src="../../../assets/credit/jiantou.png" alt="">
                    </div>
                </div>
                <div class="nameBox line">
                    <div class="left">
                        <span class="title">年龄</span>
                    </div>
                    <div class="right">
                        <van-field v-model="pageParams.spouseAge" class="labelInput" placeholder="周岁" />
                    </div>
                </div>
                <div class="nameBox line">
                    <div class="left">
                        <span class="title">配偶身份证号</span>
                    </div>
                    <div class="right">
                        <van-field v-model="pageParams.spouseIdCard" class="labelInput" placeholder="请填写配偶身份证号" />
                    </div>
                </div>
                <div class="nameBox line">
                    <div class="left">
                        <span class="title">配偶联系电话</span>
                    </div>
                    <div class="right">
                        <van-field v-model="pageParams.spousePhone" class="labelInput" placeholder="请填写配偶联系电话" />
                    </div>
                </div>
            </div>
            <!-- 名下动产1 -->
            <div v-for="(item,fileIndex) in movablesData" :key="'1'+fileIndex">
                <div class="movablesTitle">
                    <span class="title">{{fileIndex==0?'名下动产':''}}</span>
                    <span class="del" v-if="fileIndex!=0" @click="delFun(1,fileIndex)">删除</span>
                </div>
                <div class="movablesBox" :style="fileIndex > 0 ? { 'margin-top': '10px' } : {}" >
                    <div class="nameBox" >
                        <div class="left">
                            <span class="title">名下动产明细</span>
                        </div>
                        <div class="right" @click="propertyFun(fileIndex,1)">
                            <span
                                :style="item.type=='' ? { color: '#9B9B9B' } : ''">{{item.type==''?'请选择':item.type}}</span>
                            <img src="../../../assets/credit/jiantou.png" alt="">
                        </div>
                    </div>
                    <div class="nameBox line" v-if="item.type=='农机'">
                        <div class="left"></div>
                        <div class="right" @click="shapeFun(fileIndex,1)">
                            <span
                                :style="item.shape=='' ? { color: '#9B9B9B' } : ''">{{item.shape==''?'请选择':item.shape}}</span>
                            <img src="../../../assets/credit/jiantou.png" alt="">
                        </div>
                    </div>
                    <div class="nameBox line" v-if="item.type!='农机'">
                        <div class="left" style="width: 100%;">
                            <van-field style="width: 100%;padding: 0px;" v-model="item.remark" placeholder="备注（选填）" />
                        </div>
                    </div>
                    <div class="nameBox line">
                        <div class="left">
                            <span class="title">动产价值</span>
                        </div>
                        <div class="right">
                            <van-field style="width: 100px;" type="number" v-model="item.assetValue" class="labelInput"
                                placeholder="请输入价值" />
                            <span style="width:35px">万元</span>
                        </div>
                    </div>
                    <div class="nameBox line">
                        <van-uploader :after-read="file => afterReadImg(fileIndex, file, 1)"
                            @delete="(file,detail)=>deleteImg(fileIndex,detail,1)" :max-size="2000 * 1024"
                            @oversize="onOversize" v-model="item.fileList" :max-count="20"
                            class="cameraBox"></van-uploader>
                    </div>
                </div>

            </div>
            <div class="movablesAdd">
                <span>添加名下动产</span>
                <img @click="movablesAddFun(1)" src="../../../assets/credit/add.png" alt="">
            </div>
            <!-- 名下动产2 -->
            <!-- <div v-for="(item,fileIndex) in movablesData2" :key="'2'+fileIndex">
                <div class="movablesTitle">
                    <span class="title">{{fileIndex==0?'名下动产2':''}}</span>
                    <span class="del" v-if="fileIndex!=0" @click="delFun(2,fileIndex)">删除</span>
                </div>
                <div class="movablesBox" :style="fileIndex > 0 ? { 'margin-top': '10px' } : {}">
                    <div class="nameBox">
                        <div class="left">
                            <span class="title">名下动产明细</span>
                        </div>
                        <div class="right" @click="propertyFun(fileIndex,2)">
                            <span
                                :style="item.type=='' ? { color: '#9B9B9B' } : ''">{{item.type==''?'请选择':item.type}}</span>
                            <img src="../../../assets/credit/jiantou.png" alt="">
                        </div>
                    </div>
                    <div class="nameBox line">
                        <div class="left"></div>
                        <div class="right" @click="shapeFun(fileIndex,1)">
                            <span
                                :style="item.shape=='' ? { color: '#9B9B9B' } : ''">{{item.shape==''?'请选择':item.shape}}</span>
                            <img src="../../../assets/credit/jiantou.png" alt="">
                        </div>
                    </div>
                    <div class="nameBox line">
                        <div class="left">
                            <span class="title">动产价值</span>
                        </div>
                        <div class="right">
                            <van-field style="width: 100px;" v-model="item.assetValue" class="labelInput"
                                placeholder="请输入价值" />
                            <span>万元</span>
                        </div>
                    </div>
                    <div class="nameBox line">
                        <van-uploader :after-read="file => afterReadImg(fileIndex, file, 2)"
                            @delete="(file,detail)=>deleteImg(fileIndex,detail,2)" :max-size="2000 * 1024"
                            @oversize="onOversize" v-model="item.fileList" :max-count="20"
                            class="cameraBox"></van-uploader>
                    </div>
                </div>
            </div>
            <div class="movablesAdd">
                <span>添加名下动产</span>
                <img @click="movablesAddFun(2)" src="../../../assets/credit/add.png" alt="">
            </div>-->
            <!-- 名下不动产 -->
            <div v-for="(item,fileIndex) in movablesData3" :key="'3'+fileIndex">
                <div class="movablesTitle">
                    <span class="title">{{fileIndex==0?'名下不动产':''}}</span>
                    <span class="del" v-if="fileIndex!=0" @click="delFun(3,fileIndex)">删除</span>
                </div>
                <div class="movablesBox" :style="fileIndex > 0 ? { 'margin-top': '10px' } : {}">
                    <div class="nameBox">
                        <div class="left">
                            <span class="title">名下不动产明细</span>
                            <span class="tips">（房产地址楼栋号）</span>
                        </div>
                    </div>
                    <div class="nameBox line">
                        <div class="left"></div>
                        <div class="right" @click="areaFun(fileIndex)">
                            <span
                                :style="item.province=='' ? { color: '#9B9B9B' } : ''">{{item.province==''?'选择省市县区、乡镇等':(item.province
                                + '-' + item.area + '-' +
                                item.county)}}</span>
                            <img src="../../../assets/credit/jiantou.png" alt="">
                        </div>
                    </div>
                    <div class="nameBox line">
                        <div class="left" style="width: 100%;">
                            <van-field style="width: 100%;padding: 0px;" v-model="item.address"
                                placeholder="详细地址：如门牌号、小区、楼栋号等" />
                            <!-- <input v-model="item.address" placeholder="详细地址：如门牌号、小区、楼栋号等" class="remakeInput" /> -->
                        </div>
                    </div>
                    <div class="nameBox line">
                        <div class="left">
                            <span class="title">不动产价值</span>
                        </div>
                        <div class="right">
                            <van-field v-model="item.propertyValue" style="width: 100px;" class="labelInput"
                                placeholder="请输入价值" />
                            <span>万元</span>
                        </div>
                    </div>
                    <div class="nameBox line">
                        <van-uploader :after-read="file => afterReadImg(fileIndex, file, 3)"
                            @delete="(file,detail)=>deleteImg(fileIndex,detail,3)" :max-size="2000 * 1024"
                            @oversize="onOversize" v-model="item.fileList" :max-count="20"
                            class="cameraBox"></van-uploader>
                    </div>
                </div>
            </div>
            <div class="movablesAdd">
                <span>添加名下不动产</span>
                <img @click="movablesAddFun(3)" src="../../../assets/credit/add.png" alt="">
            </div>
            <!-- 经营类目 -->
            <div v-for="(item,fileIndex) in movablesData4" :key="'4'+fileIndex" >
                <div class="movablesTitle" >
                    <span class="title">{{fileIndex==0?'经营类目':''}}</span>
                    <span class="del" v-if="fileIndex!=0" @click="delFun(4,fileIndex)">删除</span>
                </div>
                <div class="movablesBox" :style="fileIndex > 0 ? { 'margin-top': '10px' } : {}">
                    <div class="nameBox">
                        <div class="left">
                            <span class="xing">*</span>
                            <span class="title">经营类目</span>
                        </div>
                        <div class="right" @click="operateFun(fileIndex)">
                            <span
                                :style="item.businessCategory=='' ? { color: '#9B9B9B' } : ''">{{item.businessCategory==''?'请选择':item.businessCategory}}</span>
                            <img src="../../../assets/credit/jiantou.png" alt="">
                        </div>
                    </div>
                    <div class="nameBox line">
                        <div class="left">
                            <span class="title">种类名称</span>
                        </div>
                        <div class="right" @click="plantFun(fileIndex)">
                            <span
                                :style="item.categoryName=='' ? { color: '#9B9B9B' } : ''">{{item.categoryName==''?'请选择':item.categoryName}}</span>
                            <img src="../../../assets/credit/jiantou.png" alt="">
                        </div>
                    </div>
                    <div class="nameBox line" v-if="item.categoryName=='其他'">
                        <div class="left" style="width: 100%;">
                            <input v-model="item.otherName" placeholder="请输入种类名称" class="remakeInput" />
                        </div>
                    </div>
                    <div class="nameBox line">
                        <div class="left">
                            <span
                                class="title">{{item.businessCategory==''?'种植':(item.businessCategory=='养殖'?'养殖':'种植')}}数量</span>
                        </div>
                        <div class="right">
                            <van-field style="width: 100px;" v-model="item.plantingQuantity" class="labelInput"
                                placeholder="请输入数量" />
                            <span>{{item.businessCategory==''?'亩':(item.businessCategory=='养殖'?'头':'亩')}}</span>
                        </div>
                    </div>
                    <div class="nameBox line">
                        <van-uploader :after-read="file => afterReadImg(fileIndex, file, 4)"
                            @delete="(file,detail)=>deleteImg(fileIndex,detail,4)" :max-size="2000 * 1024"
                            @oversize="onOversize" v-model="item.fileList" :max-count="20"
                            class="cameraBox"></van-uploader>
                    </div>
                </div>
            </div>
            <div class="movablesAdd">
                <span>添加添加经营类目</span>
                <img @click="movablesAddFun(4)" src="../../../assets/credit/add.png" alt="">
            </div>
            <!-- 位置 -->
            <div class="movablesBox paddBox">
                <div class="nameBox">
                    <div class="left" style="min-width: 80px;">
                        <span class="title">定位地址</span>
                    </div>
                    <div class="right" @click="addressShow=true">
                        <span v-if="pageParams.locationAddressType==1">{{pageParams.locationAddress==''?'请选择':pageParams.locationAddress}}</span>
                        <span v-if="pageParams.locationAddressType==2">其他</span>
                        <img src="../../../assets/credit/jiantou.png" alt="">
                    </div>
                </div>
                <div class="nameBox line line-bom" v-if="pageParams.locationAddressType==2">
                    <div class="left" style="width: 100%;">
                        <van-field style="width: 100%;padding: 0px;" v-model="pageParams.locationAddress"
                            placeholder="请输入地址" />
                    </div>
                </div>
                <div class="clockBox">
                    <div class="circle" @click="submitFrom">
                        <div class="info">
                            <div class="clockTime">{{ currentTime }}</div>
                            <div class="clockName">确定</div>
                        </div>
                    </div>
                </div>
                <div class="clockTips">注：提交后地址不可修改</div>
            </div>
        </div>
        <baidu-map style="display:none;" ak="uSDQM0SXJgOKGoiDmMxh5a1BR0zhoKVJ" @ready="handler"></baidu-map>
        <!-- 性别 -->
        <van-popup v-model="genderShow" round position="bottom" :style="{ height: '50%' }">
            <van-picker show-toolbar :columns="genderData" @confirm="genderOnConfirm" @cancel="genderOnCancel"
                :default-index="genderPickerIndex" />
        </van-popup>
        <!-- 民族 -->
        <van-popup v-model="nationShow" round position="bottom" :style="{ height: '50%' }">
            <van-picker show-toolbar :columns="nationData" @confirm="nationOnConfirm" @cancel="nationOnCancel"
                :default-index="nationPickerIndex" />
        </van-popup>
        <!-- 资产选项弹窗 -->
        <van-popup v-model="propertyShow" round position="bottom" :style="{ height: '50%' }">
            <van-picker show-toolbar :columns="property" @confirm="propertyOnConfirm" @cancel="propertyOnCancel"
                :default-index="propertyPickerIndex" />
        </van-popup>
        <!-- 资产大小 -->
        <van-popup v-model="shapeShow" round position="bottom" :style="{ height: '50%' }">
            <van-picker show-toolbar :columns="shapeData" @confirm="shapeOnConfirm" @cancel="shapeOnCancel"
                :default-index="shapePickerIndex" />
        </van-popup>
        <!-- 省市区选择 -->
        <van-popup v-model="areaShow" round position="bottom" :style="{ height: '50%' }">
            <van-area :area-list="areaList" @cancel="areaShow=false" :columns-placeholder="['请选择', '请选择', '请选择']"
                @confirm="areaConfirm" />
        </van-popup>
        <!-- 经营类目 -->
        <van-popup v-model="operateShow" round position="bottom" :style="{ height: '50%' }">
            <van-picker show-toolbar :columns="operate" @confirm="operateOnConfirm" @cancel="operateShow=false"
                :default-index="operatePickerIndex" />
        </van-popup>
        <!-- 种植类目 -->
        <van-popup v-model="plantShow" round position="bottom" :style="{ height: '50%' }">
            <van-picker show-toolbar :columns="plantData" @confirm="plantOnConfirm" @cancel="plantShow=false"
                :default-index="plantPickerIndex" />
        </van-popup>
        <!-- 养殖类目 -->
        <van-popup v-model="breedShow" round position="bottom" :style="{ height: '50%' }">
            <van-picker show-toolbar :columns="breedData" @confirm="breedOnConfirm" @cancel="breedShow=false"
                :default-index="breedPickerIndex" />
        </van-popup>
        <!-- 地址 -->
        <van-popup v-model="addressShow" round position="bottom" :style="{ height: '50%' }">
            <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="onCancel"
                :default-index="pickerIndex" />
        </van-popup>
    </div>

    </div>
</template>
<script>
    import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
    import { getUserLocation } from "@/utils/wxCodeAuth";
    import { store, upload } from "@/api/contactWelcome";
    import { areaList } from '@vant/area-data';
    import { postCredit } from "@/api/credit";
    export default {
        components: { BaiduMap },
        data() {
            return {
                areaList,
                movablesData: [{
                    fileList: [],//显示img
                    img: [],//提交img
                    type: '',
                    remark: '',
                    assetValue: '',
                    shape: ''
                }],
                movablesData2: [
                    {
                        fileList: [],//显示img
                        img: [],//提交img
                        type: '',
                        remark: '',
                        assetValue: '',
                        shape: ''
                    }
                ],
                movablesData3: [{
                    province: '',
                    area: '',
                    county: '',
                    address: '',
                    propertyValue: '',
                    fileList: [],//显示img
                    img: [],//提交img
                }],
                movablesData4: [
                    {
                        businessCategory: '',
                        categoryName: '',
                        plantingQuantity: '',
                        fileList: [],//显示img
                        img: [],//提交img
                        otherName: '',
                    }
                ],
                currentmovablesDataType: '',
                currentmovablesDataIndex: '',
                currentmShapeIndex: '',
                currentAreaIndex: '',
                currentOperateIndex: '',
                currentTime: '',
                timer: null,
                addressShow: false,
                columns: ['其他'],
                pickerIndex: 0,
                center: { lng: 116.404, lat: 39.915 },
                initialAddress: '',
                imageUrl1: null,
                imageUrl2: null,
                imageUrl3: null,
                imageUrl4: null,
                uploading: false,
                currentImg: null,
                propertyShow: false,//选项弹窗
                propertyPickerIndex: 0,
                property: ['车辆', '农机', '机器设备'],//资产选项
                shapeShow: false,
                shapePickerIndex: 0,
                shapeData: ['大', '中', '小'],
                pageParams: {
                    idPositive: '',//正面,
                    idSide: '',//反面
                    name: '',//姓名
                    sex: null,//性别
                    nation: '',//民族
                    age:'',//年龄
                    idCard: '',//身份证号
                    phone: '',//联系电话
                    householdRegistrationId: '',//户籍编号
                    teamOrFarmId: '',//所属团场连队
                    spouseName: '',//配偶姓名
                    spouseSex: null,//性别
                    spouseNation: '',//民族
                    spouseAge: '',//民族
                    spouseIdCard: '',//配偶身份证号
                    spousePhone: '',//配偶联系电话
                    spouseIdPositive: '',//正面
                    spouseIdSide: '',//反面
                    locationAddress: '',//定位地址
                    locationAddressType:1,//默认地址 2手动
                },
                areaShow: false,//省市区显示，
                operate: ['种植', '养殖', '其他'],
                operateShow: false,
                operatePickerIndex: 0,
                plantData: ['棉花', '小麦', '玉米', '大豆', '荞麦', '水果', '辣椒', '其他'],
                plantShow: false,
                plantPickerIndex: 0,
                breedData: ['牛', '羊', '骆驼', '马', '其他'],
                breedShow: false,
                currentPlantIndex: '',
                breedPickerIndex: 0,
                genderType:'',//性别类型
                nationType:'',//民族类型
                genderShow:false,
                genderPersonText:'',//显示性别
                genderSpouseText:'',//显示性别
                genderData:['男','女','未知'],
                genderPickerIndex:0,
                nationShow:false,
                nationData:['汉族','壮族','满族','回族','苗族','维吾尔族','土家族','彝族','蒙古族','藏族','布依族','侗族','瑶族','白族','朝鲜族','哈尼族','哈萨克族','黎族','傣族','畲族','傈僳族','佤族','拉祜族','景颇族','高山族','水族','东乡族','纳西族','柯尔克孜族','土族','达斡尔族','仫佬族','羌族','布朗族','撒拉族','毛南族','仡佬族','锡伯族','阿昌族','普米族','塔吉克族','怒族','乌孜别克族','俄罗斯族','鄂温克族','鄂伦春族','德昂族','保安族','裕固族','京族','塔塔尔族','独龙族','赫哲族','门巴族','珞巴族','基诺族'],
                nationPickerIndex:0,
            }
        },
        created() {

        },
        mounted() {
            this.updateTime(); // 首次加载时立即更新时间
            this.timer = setInterval(this.updateTime, 1000);// 每秒更新一次时间
            let isMobile = this.isMobileDevice()//判断是否手机端
            if (!isMobile) {
                this.$message.warning("请在使用移动端使用该功能");
            }
        },
        beforeDestroy() {
            clearInterval(this.timer);
        },
        methods: {
            updateTime() {
                const now = new Date();
                const hours = String(now.getHours()).padStart(2, '0');
                const minutes = String(now.getMinutes()).padStart(2, '0');
                const seconds = String(now.getSeconds()).padStart(2, '0');
                this.currentTime = `${hours}:${minutes}:${seconds}`;
            },
            // 文件大小
            onOversize(file) {
                this.$message.warning("文件大小不能超过 2MB");
            },
            // 上传图片文件
            afterReadImg(index, file, type) {
                file.status = "uploading";
                file.message = "上传中...";
                var formData = new FormData();
                formData.append("file", file.file);
                upload(formData).then((res) => {
                    file.status = "done";
                    if (type == 1) {
                        this.movablesData[index].img.push(res.data.path)
                    } else if (type == 2) {
                        this.movablesData2[index].img.push(res.data.path)
                    } else if (type == 3) {
                        this.movablesData3[index].img.push(res.data.path)
                    } else if (type == 4) {
                        this.movablesData4[index].img.push(res.data.path)
                    }
                });
            },
            //删除对应
            deleteImg(index, detail, type) {
                if (type == 1) {
                    this.movablesData[index].img.splice(detail.index, 1);
                } else if (type == 2) {
                    this.movablesData2[index].img.splice(detail.index, 1);
                } else if (type == 3) {
                    this.movablesData3[index].img.splice(detail.index, 1);
                } else if (type == 4) {
                    this.movablesData4[index].img.splice(detail.index, 1);
                }
            },
            afterReadImg1(file) {
                this.currentImg = 1
                this.reultUpload(file)
            },
            afterReadImg2(file) {
                this.currentImg = 2
                this.reultUpload(file)
            },
            afterReadImg3(file) {
                this.currentImg = 3
                this.reultUpload(file)
            },
            afterReadImg4(file) {
                this.currentImg = 4
                this.reultUpload(file)
            },
            reultUpload(file) {
                file.status = "uploading";
                file.message = "上传中...";
                var formData = new FormData();
                formData.append("file", file.file);
                upload(formData).then((res) => {
                    file.status = "done";
                    if (this.currentImg == 1) {
                        this.imageUrl1 = res.data.fullPath;
                        this.pageParams.idPositive = res.data.path;
                    } else if (this.currentImg == 2) {
                        this.imageUrl2 = res.data.fullPath;
                        this.pageParams.idSide = res.data.path;
                    } else if (this.currentImg == 3) {
                        this.imageUrl3 = res.data.fullPath;
                        this.pageParams.spouseIdPositive = res.data.path;
                    } else if (this.currentImg == 4) {
                        this.imageUrl4 = res.data.fullPath;
                        this.pageParams.spouseIdSide = res.data.path;
                    }
                });
            },
            //添加
            movablesAddFun(type) {
                let json = {}
                if (type == 1) {
                    json = {
                        img: [],
                        type: '',
                        remark: '',
                        assetValue: '',
                        shape: ''
                    }
                    this.movablesData.push(json)
                } else if (type == 2) {
                    json = {
                        fileList: [],//显示img
                        img: [],//提交img
                        type: '',
                        remark: '',
                        assetValue: '',
                        shape: ''
                    }
                    this.movablesData2.push(json)
                } else if (type == 3) {
                    json = {
                        province: '',
                        area: '',
                        county: '',
                        address: '',
                        propertyValue: '',
                        fileList: [],//显示img
                        img: [],//提交img
                    }
                    this.movablesData3.push(json)
                } else if (type == 4) {
                    json = {
                        businessCategory: '',
                        categoryName: '',
                        plantingQuantity: '',
                        fileList: [],//显示img
                        img: [],//提交img
                        otherName: '',
                    }
                    this.movablesData4.push(json)
                }
            },
            //删除
            delFun(type, i) {
                if (type == 1) {
                    this.movablesData.splice(i, 1)
                } else if (type == 2) {
                    this.movablesData2.splice(i, 1)
                } else if (type == 3) {
                    this.movablesData3.splice(i, 1)
                } else if (type == 4) {
                    this.movablesData4.splice(i, 1)
                }
            },
            async handler({ BMap, map }) {
                //获取用户位置
                let isMobile = await this.isMobileDevice()//判断是否手机端
                if (!isMobile) return
                const loctionInfo = await getUserLocation();
                // const loctionInfo = { longitude: 113.0750102123021, latitude: 28.169147464291367 }
                this.center = await this.qqMapTransBdMap(loctionInfo.longitude, loctionInfo.latitude)
                var that = this;
                //解析经纬度地址
                var myGeo = new BMap.Geocoder();
                var pt = new BMap.Point(this.center.lng, this.center.lat);
                myGeo.getLocation(pt, function (result) {
                    if (result) {
                        let newAddress = result.surroundingPois != 0 ? result.surroundingPois[0].title : ''
                        that.pageParams.locationAddress = result.address + newAddress
                        that.initialAddress = result.address
                        that.columns = ['其他']
                        result.surroundingPois.forEach((item, index) => {
                            that.columns.push(item.title)
                        })
                    }
                });
            },
            /**
             * @description 高德转百度
             * @description  GCJ-02 转 BD-09
             * @param{*}list [lng,lat]
             */
            qqMapTransBdMap(lng, lat) {
                let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
                let x = lng;
                let y = lat;
                let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
                let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
                let lngs = z * Math.cos(theta) + 0.0065;
                let lats = z * Math.sin(theta) + 0.006;
                return { lng: lngs, lat: lats };
            },
            // 判断是否为移动端
            isMobileDevice() {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            },
            // 选址确认
            onConfirm(value, index) {
                this.pickerIndex = index
                if(value=='其他'){
                    this.pageParams.locationAddressType=2 //手动地址
                    this.pageParams.locationAddress =''
                }else{
                    this.pageParams.locationAddressType=1
                    this.pageParams.locationAddress = this.initialAddress + value
                }
                this.addressShow = false
            },
            // 选址取消
            onCancel() {
                this.addressShow = false
            },
            //资产1选择
            propertyFun(index, type) {
                this.propertyShow = true
                this.currentmovablesDataType = type//当前类型
                this.currentmovablesDataIndex = index//当前下标
            },
            propertyOnConfirm(value, index) {
                if (this.currentmovablesDataType == 1) {
                    this.movablesData[this.currentmovablesDataIndex].type = value
                } else if (this.currentmovablesDataType == 2) {
                    this.movablesData2[this.currentmovablesDataIndex].type = value
                }
                this.propertyShow = false
            },
            propertyOnCancel() {
                this.propertyShow = false
            },
            //资产2选择
            shapeFun(index, type) {
                this.currentmShapeIndex = index//当前下标
                this.shapeShow = true
            },
            shapeOnConfirm(value, index) {
                this.movablesData[this.currentmShapeIndex].shape = value
                this.shapeShow = false
            },
            shapeOnCancel() {
                this.shapeShow = false
            },
            //省市区
            areaFun(index) {
                this.currentAreaIndex = index
                this.areaShow = true
            },
            areaConfirm(value) {
                this.movablesData3[this.currentAreaIndex].province = value[0].name
                this.movablesData3[this.currentAreaIndex].area = value[1].name
                this.movablesData3[this.currentAreaIndex].county = value[2].name
                this.areaShow = false
            },
            //经营类目
            operateOnConfirm(value, index) {
                this.movablesData4[this.currentOperateIndex].businessCategory = value
                this.movablesData4[this.currentOperateIndex].categoryName = ''
                if (this.movablesData4[this.currentOperateIndex].businessCategory == '其他') {
                    this.movablesData4[this.currentOperateIndex].categoryName = '其他'
                }
                this.operateShow = false
            },
            operateFun(index) {
                this.currentOperateIndex = index
                this.operateShow = true
            },
            plantFun(index) {
                this.currentPlantIndex = index;
                if (this.movablesData4[index].businessCategory == '') {
                    return this.$message.warning("请先选择经营类目")
                }
                this.movablesData4[index].businessCategory == '种植' ? this.plantShow = true : ''
                this.movablesData4[index].businessCategory == '养殖' ? this.breedShow = true : ''

            },
            plantOnConfirm(value) {
                this.movablesData4[this.currentPlantIndex].categoryName = value
                this.plantShow = false
            },
            breedOnConfirm(value) {
                this.movablesData4[this.currentPlantIndex].categoryName = value
                this.breedShow = false
            },
            isValidIDCard(idCard) {
                // 此处省略了详细的身份证号校验逻辑，仅展示基础的长度和格式检查
                const idCardRegex = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                return idCardRegex.test(idCard);
            },
            isValidPhone(phone) {
                const phoneRegex = /^1[3-9]\d{9}$/;
                return phoneRegex.test(phone);
            },
            //确定提交
            submitFrom() {
                if (this.pageParams.idPositive == '') {
                    if (this.$refs.location1) {
                    this.$refs.location1.$el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center'
                    });
                }
                    return this.$message.warning("请上传身份证正面")
                }
                if (this.pageParams.idSide == '') {
                    if (this.$refs.location2) {
                    this.$refs.location2.$el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center'
                    });
                }
                    return this.$message.warning("请上传身份证反面")
                }
                if (this.pageParams.name == '') {
                    if (this.$refs.location3) {
                    this.$refs.location3.$el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center'
                    });
                }
                   return this.$message.warning("请输入姓名")
                }
                if(this.pageParams.sex==null||this.pageParams.sex==''){
                    if (this.$refs.location4) {
                        this.$refs.location4.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                            inline: 'center'
                        });
                        }
                    return this.$message.warning("请选择性别")
                }
                if(this.pageParams.nation==''){
                    if (this.$refs.location5) {
                    this.$refs.location5.$el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center'
                    });
                }
                    return this.$message.warning("请选择民族")
                }
                if(this.pageParams.age==''){
                    if (this.$refs.location6) {
                    this.$refs.location6.$el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center'
                    });
                }
                    return this.$message.warning("请输入年龄")
                }
                if (this.pageParams.idCard == '') {
                    if (this.$refs.location7) {
                    this.$refs.location7.$el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center'
                    });
                }
                    return this.$message.warning("请输入身份证号")
                }
                if (!this.isValidIDCard(this.pageParams.idCard)) {
                    if (this.$refs.location7) {
                    this.$refs.location7.$el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center'
                    });
                }
                    return this.$message.warning("请输入有效的身份证号");
                }
                if (this.pageParams.phone == '') {
                    if (this.$refs.location8) {
                    this.$refs.location8.$el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center'
                    });
                }
                    return this.$message.warning("请输入联系电话")
                }
                if (!this.isValidPhone(this.pageParams.phone)) {
                    if (this.$refs.location8) {
                    this.$refs.location8.$el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center'
                    });
                }
                    return this.$message.warning("请输入有效的联系电话");
                }
                if (this.pageParams.householdRegistrationId == '') {
                    if (this.$refs.location9) {
                    this.$refs.location9.$el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center'
                    });
                   }
                    return this.$message.warning("请输入户籍编号")
                }
                if (this.pageParams.teamOrFarmId == '') {
                    if (this.$refs.location10) {
                    this.$refs.location10.$el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center'
                    });
                    }
                    return this.$message.warning("请输入所属团场连队")
                }
                if (this.pageParams.spouseIdCard != '' && !this.isValidIDCard(this.pageParams.spouseIdCard)) {
                    return this.$message.warning("请输入有效的配偶身份证号");
                }
                if (this.pageParams.spousePhone != '' && !this.isValidPhone(this.pageParams.spousePhone)) {
                    return this.$message.warning("请输入有效的配偶联系电话");
                }
                //判断this.movablesData是否填写有必填数据
                for (const item of this.movablesData) {
                    if (item.type === '' && item.assetValue !== '') {
                        this.$message.warning("请选择明细类型");
                        return;
                    } else if (item.type !== '' && item.assetValue === '') {
                        this.$message.warning("请填写价值！");
                        return;
                    }
                }
                //判断this.movablesData2是否填写有必填数据
                // for (const item of this.movablesData2) {
                //     if (item.type === '' && item.assetValue !== '') {
                //         this.$message.warning("请选择明细类型");
                //         return;
                //     } else if (item.type !== '' && item.assetValue === '') {
                //         this.$message.warning("请填写价值！");
                //         return;
                //     }
                // }
                //判断this.movablesData3是否填写有必填数据
                for (const item of this.movablesData3) {
                    if (item.province === '' && item.propertyValue !== '') {
                        this.$message.warning("请选择地址");
                        return;
                    } else if (item.province !== '' && item.propertyValue === '') {
                        this.$message.warning("请填写价值！");
                        return;
                    }
                }
                //判断this.movablesData4必填项是否必填
                let missingFields = [];
                for (const item of this.movablesData4) {
                    const fields = ['businessCategory', 'categoryName', 'plantingQuantity'];
                    const filledFields = fields.filter(f => item[f]);
                    if (filledFields.length > 0 && filledFields.length < 3) {
                        missingFields = fields.filter(f => !item[f]);
                        break;
                    }
                }
                const fieldNames = {
                    businessCategory: '经营类目',
                    categoryName: '种类名称',
                    plantingQuantity: '数量'
                };
                if (missingFields.length > 0) {
                    const missingFieldsStr = missingFields.map(f => fieldNames[f]).join('、');
                    this.$message.warning(`请填写：${missingFieldsStr}。`);
                    return;
                }
                //判断this.movablesData4是否所有数据为空
                const requiredFields = ['businessCategory', 'categoryName', 'plantingQuantity'];
                const hasEmptyField = this.movablesData4.some(item =>
                    requiredFields.some(field => item[field] === undefined || item[field] === null || item[field] === '')
                );
                if (hasEmptyField) {
                    this.$message.warning('请检查填写经营类目数据！');
                    return
                }
                let newParams = this.pageParams
                //移除this.movablesData  必填项为空的  防止提交空数据
                let newMovablesData = this.movablesData
                    .reduce((accumulator, current) => {
                        if (current.type !== '') {
                            accumulator.push({ ...current, fileList: undefined });
                        }
                        return accumulator;
                    }, []);
                //  //移除this.movablesData2  必填项为空的  防止提交空数据
                // let newMovablesData2 = this.movablesData2
                //     .reduce((accumulator, current) => {
                //         if (current.type !== '') {
                //             accumulator.push({ ...current, fileList: undefined });
                //         }
                //         return accumulator;
                //     }, []);
                //移除this.movablesData3  必填项为空的  防止提交空数据
                let newMovablesData3 = this.movablesData3
                    .reduce((accumulator, current) => {
                        if (current.province !== '') {
                            accumulator.push({ ...current, fileList: undefined });
                        }
                        return accumulator;
                    }, []);
                //移除this.movablesData4  必填项为空的  防止提交空数据
                let newMovablesData4 = this.movablesData4
                    .reduce((accumulator, current) => {
                        if (current.businessCategory !== '') {
                            accumulator.push({ ...current, fileList: undefined });
                        }
                        return accumulator;
                    }, []);
                this.pageParams.assetDetail = JSON.stringify(newMovablesData) //动产数据合并
                this.pageParams.propertyDetail = JSON.stringify(newMovablesData3)//不动产数据
                this.pageParams.businessCategory = JSON.stringify(newMovablesData4)//经营类目
                postCredit(this.pageParams).then((res) => {
                    if (res.code == 200) {
                        this.$message.success("提交成功")
                        this.$emit('change');
                        this.reastPageData()
                    }
                })
            },
            //重置页面数据
            reastPageData() {
                this.movablesData = [{
                    fileList: [],//显示img
                    img: [],//提交img
                    type: '',
                    remark: '',
                    assetValue: '',
                }];
                this.movablesData2 = [
                    {
                        fileList: [],//显示img
                        img: [],//提交img
                        type: '',
                        remark: '',
                        assetValue: '',
                        shape: ''
                    }
                ];
                this.movablesData3 = [{
                    province: '',
                    area: '',
                    county: '',
                    address: '',
                    propertyValue: '',
                    fileList: [],//显示img
                    img: [],//提交img
                }];
                this.movablesData4 = [
                    {
                        businessCategory: '',
                        categoryName: '',
                        plantingQuantity: '',
                        fileList: [],//显示img
                        img: [],//提交img
                        otherName: '',
                    }
                ];
                this.pageParams = {
                    idPositive: '',//正面,
                    idSide: '',//反面
                    name: '',//姓名
                    idCard: '',//身份证号
                    phone: '',//联系电话
                    householdRegistrationId: '',//户籍编号
                    teamOrFarmId: '',//所属团场连队
                    spouseName: '',//配偶姓名
                    spouseIdCard: '',//配偶身份证号
                    spousePhone: '',//配偶联系电话
                    spouseIdPositive: '',//正面
                    spouseIdSide: '',//反面
                    locationAddress: '',//定位地址
                };
                this.imageUrl1 = null;
                this.imageUrl2 = null;
                this.imageUrl3 = null;
                this.imageUrl4 = null;
            },
            genderShowFun(type){
                this.genderType=type
                this.genderShow=true
            },
            nationShowFun(type){
                this.nationType=type
                this.nationShow=true
            },
            //性别确认
            genderOnConfirm(value){
                let json={
                    '男':1,
                    '女':2,
                    '未知':3
                }
              if(this.genderType=='person'){
                this.pageParams.sex = json[value]
                this.genderPersonText=value
              }else if(this.genderType=='spouse'){
                this.pageParams.spouseSex = json[value]
                this.genderSpouseText=value
              }
              this.genderShow=false
            },
            //性别取消
            genderOnCancel(){
              this.genderShow=false
            },
            //民族确认
            nationOnConfirm(value){
                if(this.nationType=='person'){
                this.pageParams.nation = value
              }else if(this.nationType=='spouse'){
                this.pageParams.spouseNation = value
              }
              this.nationShow=false
            },
            //民族取消
            nationOnCancel(){
              this.nationShow=false
            },
        }
    }
</script>
<style lang="less" scoped>
    /deep/ .van-field__control {
        text-align: right;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
    }

    .contentBox {
        padding: 12px;

        .card {
            padding: 15px 18px;
            background: #FFFFFF;
            border-radius: 5px;


            .head {
                .xing {
                    font-weight: bold;
                    font-size: 17px;
                    color: #CD0005;
                    margin-right: 5px;
                }

                .title {
                    font-weight: bold;
                    font-size: 17px;
                    color: #000000;
                }

                .tips {
                    margin-left: 17px;
                    font-weight: 400;
                    font-size: 15px;
                    color: #A6C5F6;
                }
            }

            .pic {
                margin-top: 17px;
                display: flex;
                gap: 10px;

                .picBox {
                    width: 50%;
                    height: 118px;
                    background: #F1F2F6;
                    border-radius: 4px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: relative;

                    .defaltImg {
                        width: 94px;
                        height: 56px;
                        margin-bottom: 29px;
                    }

                    .showImg {
                        width: 100%;
                        height: 118px;
                        border-radius: 4px;
                    }

                    .title {
                        position: absolute;
                        bottom: 0px;
                        height: 29px;
                        font-weight: 400;
                        font-size: 15px;
                        color: #3974C6;
                        width: 100%;
                        text-align: center;
                        line-height: 29px;
                        background: #DCE6F6;
                        border-radius: 0px 0px 4px 4px;
                    }
                }
            }

            .picTips {
                margin-top: 15px;
                font-weight: 400;
                font-size: 12px;
                color: #A6C5F6;
            }

            .line {
                border-top: 1px solid #F5F5F5;
            }

            .nameBox {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 12px 0;

                .left {
                    .xing {
                        font-weight: bold;
                        font-size: 17px;
                        color: #CD0005;
                        margin-right: 5px;
                    }

                    .title {
                        font-weight: bold;
                        font-size: 17px;
                        color: #000000;
                    }
                }

                .right {
                    display: flex;
                    align-items: center;
                    font-weight: 400;
                    font-size: 14px;
                    color: #000000;

                    .labelInput {
                        text-align: right;
                        padding: 0px;
                        height: 100%;
                    }
                    span {
                        font-weight: 400;
                        font-size: 14px;
                        color: #000000;
                    }

                    img {
                        width: 6px;
                        height: 10px;
                        margin-left: 6px;
                    }

                }

            }
        }

        .spouse {
            margin-top: 10px;
        }

        .movablesTitle {
            display: flex;
            justify-content: space-between;
            margin: 13px 0;

            .title {
                font-weight: 500;
                font-size: 15px;
                color: #515151;
            }

            .del {
                font-weight: 400;
                font-size: 12px;
                color: #9B9B9B;
            }
        }

        .movablesBox {
            padding: 0px 20px;
            background: #FFFFFF;
            border-radius: 5px;

            .line {
                border-top: 1px solid #F5F5F5;
            }
            .line-bom{
                border-bottom: 1px solid #F5F5F5;
            }
            .nameBox {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 12px 0;

                .left {
                    .xing {
                        font-weight: bold;
                        font-size: 17px;
                        color: #CD0005;
                        margin-right: 5px;
                    }

                    .title {
                        font-weight: bold;
                        font-size: 17px;
                        color: #000000;
                    }

                    .remakeInput {
                        border: none;
                        width: 100%;
                        font-weight: 400;
                        font-size: 14px;
                        color: #9B9B9B;
                        text-align: right;
                    }

                    .tips {
                        font-weight: 400;
                        font-size: 14px;
                        color: #9B9B9B;
                    }
                }

                .right {
                    display: flex;
                    align-items: center;

                    span {
                        font-weight: 400;
                        font-size: 14px;
                        color: #000000;
                    }

                    img {
                        width: 6px;
                        height: 10px;
                        margin-left: 6px;
                    }
                }

            }

            .clockBox {
                margin-top: 24px;
                width: 100%;
                display: flex;
                justify-content: center;

                .circle {
                    width: 118px;
                    height: 118px;
                    position: relative;
                    background: #3975C6;
                    border: 8px solid #CDE3FF;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .info {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-align: center;
                        z-index: 2;

                        .clockTime {
                            font-size: 19px;
                            font-weight: bold;
                            color: #FFFFFF;
                        }

                        .clockName {
                            font-size: 16px;
                            font-weight: 500;
                            color: #FFFFFF;
                        }
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        top: -8px;
                        left: -8px;
                        width: calc(100% + 16px);
                        height: calc(100% + 16px);
                        border: 8px solid #CDE3FF;
                        border-radius: 50%;
                        animation: wave 2s linear infinite;
                        z-index: 1;
                    }
                }
            }

            .clockTips {
                width: 100%;
                text-align: center;
                font-weight: 400;
                font-size: 11px;
                color: #9B9B9B;
                margin-top: 25px;
            }

            @keyframes wave {
                0% {
                    box-shadow: 0 0 0 0 #CDE3FF;
                }

                50% {
                    box-shadow: 0 0 0 8px #CDE3FF;
                }

                100% {
                    box-shadow: 0 0 0 0 #CDE3FF;
                }
            }
        }

        .paddBox {
            margin-top: 10px;
            padding-bottom: 20px;
        }

        .movablesAdd {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 14px 20px;
            background: #FFFFFF;
            border-radius: 5px;
            margin-top: 10px;

            span {
                font-weight: bold;
                font-size: 15px;
                color: #515151;
            }

            img {
                width: 25px;
                height: 25px;
            }
        }
    }
</style>